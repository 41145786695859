import React, { Component } from 'react'
import logo from './assets/layer.png'
import ReactGA from 'react-ga'
import './App.scss'

const track = (category, action) => ReactGA.event({ category, action })

class App extends Component {
  render() {
    return (
      <div
        className="app"
        onClick={e => {
          const category = e.target.innerText
          if (category.length < 50) {
            track(category, 'click')
          }
        }}
      >
        <div className="app__content">
          <div className="app__header">
            <div className="app__logo">
              <img alt="logo" src={logo} />
            </div>
          </div>
          <div className="app__spacing" />
          <div className="app__top">
            <div className="app__main-text-wrapper">
              <div className="app__main-text">Cryptocurrency consulting for individuals and institutions</div>
            </div>
          </div>

          <div className="app__bottom">
            <div className="app__main-text-wrapper">
              <div className="app__point">
                <span className="text--bold">Email </span>- hello@cryptodex.io
              </div>
            </div>
          </div>
        </div>
        {/*<div className="app__actions">
          <a href="https://github.com/lockfinance" target="_blank" rel="noopener noreferrer">
            GitHub
          </a>
          <a href="https://twitter.com/lockprotocol" target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
          <a href="https://medium.com/lockprotocol" target="_blank" rel="noopener noreferrer">
            Medium
          </a>
          <a href="mailto:hello@lock.finance">Email</a>
      </div>*/}
      </div>
    )
  }
}

export default App
